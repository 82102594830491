<template>
  <v-app>
    <v-container>
      <!-- Background Image Section with Text Overlay -->
      <v-row justify="center" class="my-5">
        <v-col cols="12" class="text-center background-container">
          <!-- Yıl Sonu Duyurusu -->
          <div class="announcement-container">
            <p class="announcement-text">
              Yıl sonu sebebi ile <span class="highlighted-dates">28.12.2024 - 01.01.2025 </span> tarihleri arasında
              sistemimiz kapalıdır.
            </p>
            <p class="announcement-text">Anlayışınız için teşekkür ederiz.</p>
          </div>

          <!-- Arka Plan Görseli -->
          <div class="overlay-text">
            <p class="subheading">
              Yeni yıl, umutlar ve yeniliklerle dolu bir yıl olsun. <br />
              Mutlu Seneler...
            </p>
          </div>

          <!-- Logo -->
          <div class="logo-container">
            <img src="@/assets/images/logos/anlas-logo.png" alt="Logo" class="logo" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  height: 100%; /* Sayfa yüksekliğini tam ekran yapar */
  width: 100%; /* Sayfa genişliğini tam ekran yapar */
  overflow: hidden; /* Kaydırma çubuğunu gizler */
  background-color: #28243d; /* Mavi renk kodu */
}

body {
  display: flex;
  flex-direction: column;
  height: 100%; /* Tam ekran boyutunda olmasını sağlar */
}
.subheading {
  font-size: 1.5rem;
  color: white;
  font-style: italic;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap; /* Metni tek satırda tutmak için */
}

.logo-container {
  position: absolute;
  top: 70%; /* "Hoş Geldin 2024!" yazısından sonra gelmesi için oranla ayarladık */
  left: 60%;
  transform: translateX(-50%);
  width: 200px; /* Logo container'ın genişliğini sınırlıyoruz */
  text-align: center; /* İçerikleri ortalamak için */
  margin-top: 20px; /* "Mutlu Seneler" yazısının altına biraz boşluk ekledik */
}

.logo {
  max-width: 100%; /* Logonun genişliği %100 olacak */
  width: auto; /* Orantılı genişlik */
  height: auto; /* Orantılı yükseklik */
}

.display-2 {
  font-size: 3rem;
}

.headline {
  font-size: 1.5rem;
}

.announcement-text {
  font-size: 1.2rem;
  color: white; /* Duyuru metnini beyaz yapmak için */
  font-weight: bold;
  margin-top: 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Metni okunabilir kılmak için gölge ekledik */
}

.announcement-container {
  position: absolute;
  top: 25%; /* Duyuruyu arka planın üst kısmına daha yakın yerleştiriyoruz */
  left: 60%;
  transform: translateX(-50%);
  z-index: 1;
  width: 80%; /* Duyuru metninin genişliğini kısıtlıyoruz */
  text-align: center;
}

.overlay-text h2 {
  color: white;
  font-weight: bold;
}

.overlay-text p {
  font-size: 1.5rem;
  color: white;
  font-style: italic;
}

.background-container {
  background-color: #28243d;
  position: relative;
  background-image: url('~@/assets/images/new-year.png'); /* Çam ağacı süsleri arka plan resmi */
  background-size: contain; /* Görselin boyutunu ekranın içine sığdırır, büyütmeden */
  background-position: left top; /* Görseli sola yaslar */
  background-repeat: no-repeat; /* Görseli tekrarlamaz */
  height: 100vh; /* Ekran boyutunda yüksekliği ayarlar */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* İçerik taşarsa gizle */
}

.overlay-text {
  position: absolute;
  top: 60%;
  left: 60%;
  transform: translateX(-50%) translateY(-50%);
  color: white;
  text-align: center;
  z-index: 1;
}

footer {
  display: none; /* Footer'ı gizledik */
}

.highlighted-dates {
  font-size: 1.4rem; /* Tarih fontunu büyütüyoruz */
  font-weight: bold; /* Tarihleri kalın yapıyoruz */
  text-decoration: underline; /* Altını çiziyoruz */
  text-transform: uppercase; /* Tarihleri büyük harf yapıyoruz */
  padding: 0 10px; /* Sağ ve sol tarafına boşluk ekliyoruz */
}

@media (max-width: 600px) {
  .subheading {
    font-size: 1rem; /* Mobilde daha küçük boyut */
    color: white;
    font-style: italic;
    text-align: center;
    line-height: 1.5;
    white-space: normal; /* Metnin taşmasını önler */
    padding: 0 10px; /* Yanlardan boşluk ekler */
  }
  .logo-container {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
  }
  .announcement-container {
    top: 30%; /* Mobilde daha üstte görünmesi için */
    left: 50%;
    transform: translate(-50%, 0);
    width: 90%; /* Genişliği kısıtlar */
    padding: 10px; /* Kenarlardan boşluk ekler */
    font-size: 1rem;
  }

  .overlay-text {
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem; /* Mobilde daha küçük boyut */
    padding: 0 10px; /* Yanlardan boşluk ekler */
  }
}
</style>
